import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "download"
    }}>{`Download`}</h1>
    <p>{`To get started with the Progress Mfg. you have a couple options with Progress Mfg. stack.  You can either start your project with `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsbyjs`}</a>{` or `}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`Nextjs`}</a>{`. It is preferred for websites to use Gatsbyjs and for web applications to use Nextjs as it is an isomorphic application framework.`}</p>
    <p>{`Both of these can be pulled from our bitbucket repositories and updated to to latest versions of npm packages.`}</p>
    <h2 {...{
      "id": "gatsbyjs-framework"
    }}>{`Gatsbyjs Framework`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git clone git@bitbucket.org:progressmanufacturing/progress-ui_gatsbyjs.git <dir>
`}</code></pre>
    <h2 {...{
      "id": "nextjs-framework"
    }}>{`Nextjs Framework`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git clone git@bitbucket.org:progressmanufacturing/progress-ui_nextjs.git <dir>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      